import React, {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { DisplayMode } from 'shared/dist/types';

import type { DisplayProviderProps, UseDisplayProps } from './types';

const isServer = typeof window === 'undefined';
const DisplayContext = createContext<UseDisplayProps | undefined>(undefined);

const defaultContext: UseDisplayProps = {
  setDisplayMode: () => {
    console.log('setting display mode');
  },
  displayModes: []
};

export const useDisplayViewMode = () => useContext(DisplayContext) ?? defaultContext;

export const DisplayViewProvider: React.FC<DisplayProviderProps> = (props) => {
  const context = useContext(DisplayContext);

  // Ignore nested context providers, just passthrough children
  if (context) return <Fragment>{props.children}</Fragment>;
  return <DisplayViewMode {...props} />;
};

const DisplayViewMode: React.FC<DisplayProviderProps> = ({
  forcedTheme,
  enableSystem = true,
  storageKey = 'displayMode',
  displayModes = [DisplayMode.GRID, DisplayMode.LIST, DisplayMode.GEO],
  defaultDisplayMode = DisplayMode.GRID,
  children
}) => {
  const [displayMode, setDisplayModeState] = useState(() =>
    getDisplayMode(storageKey, defaultDisplayMode)
  );

  const setDisplayMode = useCallback(
    (displayMode) => {
      setDisplayModeState(displayMode);
      // Save to storage
      try {
        localStorage.setItem(storageKey, displayMode);
      } catch (e) {
        // Unsupported
        console.log(e);
      }
    },
    [forcedTheme, storageKey]
  );

  // localStorage event handling
  useEffect(() => {
    const handleStorage = (e: StorageEvent) => {
      if (e.key !== storageKey) {
        return;
      }

      // If default theme set, use it if localstorage === null (happens on local storage manual deletion)
      const displayMode = e.newValue || defaultDisplayMode;
      setDisplayMode(displayMode);
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [setDisplayMode, defaultDisplayMode, storageKey]);

  return (
    <DisplayContext.Provider
      value={{
        displayMode,
        setDisplayMode,
        forcedTheme,
        displayModes: enableSystem ? [...displayModes, 'system'] : displayModes
      }}>
      {children}
    </DisplayContext.Provider>
  );
};

// Helpers
const getDisplayMode = (key: string, fallback?: string) => {
  if (isServer) return undefined;

  let displayMode;

  try {
    displayMode = localStorage.getItem(key) || undefined;
  } catch (e) {
    // Unsupported
  }
  return displayMode || fallback;
};
