import React, {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { PropertyTypeGroup } from 'shared/dist/types';

import type { PropertyTypeProviderProps, UsePropertyTypeProps } from './types';

const isServer = typeof window === 'undefined';
const PropertyTypeContext = createContext<UsePropertyTypeProps | undefined>(undefined);

const defaultContext: UsePropertyTypeProps = {
  setPropertyTypesMode: () => {
    console.log('setting property type');
  },
  availablePropertyTypes: [],
  availablePropertyTypesRent: [],
  availablePropertyTypesExt: []
};

export const usePropertyTypesMode = () => useContext(PropertyTypeContext) ?? defaultContext;

export const PropertyTypesProvider: React.FC<PropertyTypeProviderProps> = (props) => {
  const context = useContext(PropertyTypeContext);

  // Ignore nested context providers, just passthrough children
  if (context) return <Fragment>{props.children}</Fragment>;
  return <PropertyTypesMode {...props} />;
};

const PropertyTypesMode: React.FC<PropertyTypeProviderProps> = ({
  forcedTheme,
  storageKey = 'propertyTypes',
  availablePropertyTypes = [
    PropertyTypeGroup.HOUSING,
    PropertyTypeGroup.SPACE,
    PropertyTypeGroup.WAREHOUSE,
    PropertyTypeGroup.INDUSTRY,
    PropertyTypeGroup.SPECIAL,
    PropertyTypeGroup.STREET
  ],
  availablePropertyTypesRent = [
    PropertyTypeGroup.HOUSING,
    PropertyTypeGroup.SPACE,
    PropertyTypeGroup.WAREHOUSE
  ],
  availablePropertyTypesExt = [
    PropertyTypeGroup.HOUSING,
    PropertyTypeGroup.SPACE,
    PropertyTypeGroup.WAREHOUSE,
    PropertyTypeGroup.INDUSTRY,
    PropertyTypeGroup.SPECIAL,
    PropertyTypeGroup.STREET,
    PropertyTypeGroup.PRIVATE
  ],
  defaultPropertyTypes = [
    // PropertyTypeGroup.HOUSING,
    // PropertyTypeGroup.SPACE,
    // PropertyTypeGroup.WAREHOUSE,
    // PropertyTypeGroup.INDUSTRY,
    // PropertyTypeGroup.SPECIAL
  ],
  children
}) => {
  const [propertyTypes, setPropertyTypesState] = useState(() =>
    getPropertyTypes(storageKey, defaultPropertyTypes)
  );

  const setPropertyTypesMode = useCallback(
    (propertyTypes) => {
      setPropertyTypesState(propertyTypes);
      // Save to storage
      try {
        localStorage.setItem(storageKey, propertyTypes);
      } catch (e) {
        // Unsupported
        console.log(e);
      }
    },
    [forcedTheme, storageKey]
  );

  // localStorage event handling
  useEffect(() => {
    const handleStorage = (e: StorageEvent) => {
      if (e.key !== storageKey) {
        return;
      }
      const propertyTypes = e.newValue || defaultPropertyTypes;
      setPropertyTypesMode(propertyTypes);
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [setPropertyTypesMode, defaultPropertyTypes, storageKey]);

  return (
    <PropertyTypeContext.Provider
      value={{
        propertyTypes,
        setPropertyTypesMode,
        forcedTheme,
        availablePropertyTypes,
        availablePropertyTypesRent,
        availablePropertyTypesExt
      }}>
      {children}
    </PropertyTypeContext.Provider>
  );
};

// Helpers
const getPropertyTypes = (key: string, fallback?: PropertyTypeGroup[]) => {
  if (isServer) return undefined;

  let propertyTypes;

  try {
    const propertyTypesString: string | null = localStorage.getItem(key) || null;
    propertyTypes = propertyTypesString ? propertyTypesString?.split(`,`) : null;
  } catch (e) {
    // Unsupported
  }
  return propertyTypes || fallback;
};
