import React, {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import type { MapStyleProviderProps, UseMapStyleProps } from './types';

const isServer = typeof window === 'undefined';
const MapStyleContext = createContext<UseMapStyleProps | undefined>(undefined);

const defaultContext: UseMapStyleProps = {
  setMapStyleMode: () => {
    console.log('setting property type');
  },
  mapStyles: []
};

export const useMapStyles = () => useContext(MapStyleContext) ?? defaultContext;

export const MapStyleProvider: React.FC<MapStyleProviderProps> = (props) => {
  const context = useContext(MapStyleContext);

  // Ignore nested context providers, just passthrough children
  if (context) return <Fragment>{props.children}</Fragment>;
  return <MapStyleMode {...props} />;
};

const MapStyleMode: React.FC<MapStyleProviderProps> = ({
  forcedMapStyle,
  storageKey = 'mapStyle',
  mapStyles = ['light', 'dark', 'satellite', 'lantmateriet'],
  defaultMapStyle = 'light',
  children
}) => {
  const [mapStyle, setMapStyleState] = useState(() => getMapStyle(storageKey, defaultMapStyle));

  const setMapStyleMode = useCallback(
    (mapStyle) => {
      setMapStyleState(mapStyle);
      // Save to storage
      try {
        localStorage.setItem(storageKey, mapStyle);
      } catch (e) {
        // Unsupported
        console.log(e);
      }
    },
    [forcedMapStyle, storageKey]
  );

  // localStorage event handling
  useEffect(() => {
    const handleStorage = (e: StorageEvent) => {
      if (e.key !== storageKey) {
        return;
      }
      const mapStyle = e.newValue || defaultMapStyle;
      setMapStyleMode(mapStyle);
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [setMapStyleMode, defaultMapStyle, storageKey]);

  return (
    <MapStyleContext.Provider
      value={{
        mapStyles,
        mapStyle,
        setMapStyleMode,
        forcedMapStyle
      }}>
      {children}
    </MapStyleContext.Provider>
  );
};

// Helpers
const getMapStyle = (key: string, fallback?: string) => {
  if (isServer) return undefined;

  let mapStyle;

  try {
    const mapStyle: string | null = localStorage.getItem(key) || null;
    // mapStyle = propertyTypesString ? propertyTypesString?.split(`,`) : null;
  } catch (e) {
    // Unsupported
  }
  return mapStyle || fallback;
};
