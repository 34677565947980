import React, {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

import type { BboxProviderProps, UseBboxProps } from './types';

// const MEDIA = '(prefers-color-scheme: dark)'
const isServer = typeof window === 'undefined';
const BboxContext = createContext<UseBboxProps | undefined>(undefined);
const defaultContext: UseBboxProps = {
  setBbox: () => {
    console.log(`setting bbox`);
  },
  bboxes: []
};

export const useBbox = () => useContext(BboxContext) ?? defaultContext;
export const BboxProvider: React.FC<BboxProviderProps> = (props) => {
  const context = useContext(BboxContext);

  // Ignore nested context providers, just passthrough children
  if (context) return <Fragment>{props.children}</Fragment>;

  return <Bbox {...props} />;
};

const Bbox: React.FC<BboxProviderProps> = ({
  forcedBbox,
  storageKey = 'bbox',
  enableSystem = true,
  bboxes = [],
  defaultBbox = [
    [11.0273686052, 52.7617373725],
    [23.9033785336, 68.1062472602]
  ],
  children
}) => {
  const [bbox, setBboxState] = useState(() => getBbox(storageKey, defaultBbox));
  const [resolvedTheme] = useState(() => getBbox(storageKey));

  const setBbox = useCallback(
    (bbox) => {
      setBboxState(bbox);
      // Save to storage
      try {
        localStorage.setItem(storageKey, JSON.stringify(bbox));
      } catch (e) {
        // Unsupported
        //console.log(e);
      }
    },
    [forcedBbox, storageKey]
  );

  // localStorage event handling
  useEffect(() => {
    const handleStorage = (e: StorageEvent) => {
      if (e.key !== storageKey) {
        return;
      }

      // If default theme set, use it if localstorage === null (happens on local storage manual deletion)
      const bbox = e.newValue || defaultBbox;
      setBbox(bbox);
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [setBbox, defaultBbox, storageKey]);

  return (
    <BboxContext.Provider
      value={{
        bbox,
        setBbox,
        forcedBbox,
        resolvedTheme: bbox === 'system' ? resolvedTheme : bbox,
        bboxes: bboxes,
        systemTheme: (enableSystem ? resolvedTheme : undefined) as 'light' | 'dark' | undefined
      }}>
      {children}
    </BboxContext.Provider>
  );
};

// Helpers
const getBbox = (key: string, fallback?: [[number, number], [number, number]]) => {
  if (isServer) return undefined;
  let bbox;

  try {
    bbox = localStorage.getItem(key) || undefined;
    bbox = JSON.parse(bbox);
  } catch (e) {
    // Unsupported
  }
  return bbox || fallback;
};
