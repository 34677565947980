/** context provider for global dialogs (modals) */
import React, { createContext, useState } from 'react';

import { DialogType } from '../types';

export type DialogValueType = {
  dialogType: DialogType;
  closeableByOutsideClick?: boolean;
  closeable?: boolean;
  object?: any;
  dialogStyle?: React.CSSProperties;
  onClose?: () => void;
} | null;

type DialogContextType = {
  dialog: DialogValueType;
  setDialog: (a: DialogValueType) => void;
};

export const DialogContext = createContext<DialogContextType>({
  dialog: null,
  setDialog: () => console.log('ctx is not defined')
});

interface DialogContextProviderProps {
  children: React.ReactNode | React.ReactChild;
}

export const useDialog: () => DialogContextType = () => {
  const ctx = React.useContext(DialogContext);

  if (ctx === undefined) {
    throw new Error(`useDialog should be used within DialogProvider`);
  }
  return ctx;
};

export const DialogProvider: React.FC<DialogContextProviderProps> = ({ children }) => {
  const [dialog, setDialog] = useState<DialogValueType>(null);

  const value = {
    dialog,
    setDialog
  };

  return <DialogContext.Provider value={value}>{children}</DialogContext.Provider>;
};
