import { getCookie, setCookie } from 'cookies-next';
import React, {
  createContext,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { MenuMode } from 'shared/dist/types';

import type { MenuProviderProps, UseMenuProps } from './types';

const MenuContext = createContext<UseMenuProps | undefined>(undefined);

const defaultContext: UseMenuProps = {
  setMenuMode: () => {
    console.log('setting Menu mode');
  },
  menuModes: []
};

export const useMenuMode = () => useContext(MenuContext) ?? defaultContext;

export const MenuModeProvider: React.FC<MenuProviderProps> = (props) => {
  const context = useContext(MenuContext);

  // Ignore nested context providers, just passthrough children
  if (context) return <Fragment>{props.children}</Fragment>;
  return <MenuModeCtx {...props} />;
};

const MenuModeCtx: React.FC<MenuProviderProps> = ({
  forcedTheme,
  enableSystem = true,
  storageKey = 'menuMode',
  menuModes = [
    MenuMode.COLLAPSED,
    MenuMode.EXPANDED_FIXED,
    MenuMode.MOBILE_OPEN,
    MenuMode.MOBILE_CLOSED
  ],
  defaultMenuMode = MenuMode.COLLAPSED,
  children
}) => {
  const [menuMode, setMenuModeState] = useState(() => MenuMode.COLLAPSED);

  useEffect(() => {
    let id: any = null;
    if (typeof window !== 'undefined') {
      id = setTimeout(() => {
        setMenuModeState(getMenuMode(storageKey, defaultMenuMode));
      }, 0);
    }
    return () => clearTimeout(id);
  }, [defaultMenuMode, storageKey]);

  const setMenuMode = useCallback(
    (menuMode) => {
      setMenuModeState(menuMode);
      // Save to cookies
      try {
        const secure = location.protocol.startsWith('https');
        setCookie(storageKey, menuMode, { secure });
      } catch (e) {
        // Unsupported
        console.log(e);
      }
    },
    [forcedTheme, storageKey]
  );

  // cookie event handling
  useEffect(() => {
    const handleStorage = (e: StorageEvent) => {
      if (e.key !== storageKey) {
        return;
      }

      // If default theme set, use it if the is no menuMode cookie (happens on cookie manual deletion)
      const menuMode = e.newValue || defaultMenuMode;
      setMenuMode(menuMode);
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, [setMenuMode, defaultMenuMode, storageKey]);

  return (
    <MenuContext.Provider
      value={{
        menuMode,
        setMenuMode,
        forcedTheme,
        menuModes: enableSystem ? [...menuModes, 'system'] : menuModes
      }}>
      {children}
    </MenuContext.Provider>
  );
};

// Helpers
const getMenuMode = (key: string, fallback?: string) => {
  let menuMode;

  try {
    menuMode = getCookie(key) || undefined;
  } catch (e) {
    // Unsupported
  }
  return menuMode || fallback;
};
