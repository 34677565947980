import React, { createContext, useState } from 'react';

import { SlideInType } from '../types';

type SlideInContextType = {
  slideIn: SlideInType | null;
  setSlideIn: (value: SlideInType | null) => void;
};

export const SlideInContext = createContext<SlideInContextType>({
  slideIn: null,
  setSlideIn: () => console.log('ctx is not defined')
});

interface SlideInContextProviderProps {
  children: React.ReactNode | React.ReactChild;
}

export const useSlideIn: () => SlideInContextType = () => {
  const ctx = React.useContext(SlideInContext);

  if (ctx === undefined) {
    throw new Error(`useSideIn should be used within SideInProvider`);
  }
  return ctx;
};

export const SlideInProvider: React.FC<SlideInContextProviderProps> = ({ children }) => {
  const [slideIn, setSlideIn] = useState<SlideInType | null>(null);

  const value = {
    slideIn,
    setSlideIn
  };

  return <SlideInContext.Provider value={value}>{children}</SlideInContext.Provider>;
};
