import React, { createContext, useState } from 'react';

type HideBRFContextType = {
  hideBRF: number | null;
  setHideBRF: (value: any) => void;
};

export const HideBRFContext = createContext<HideBRFContextType>({
  hideBRF: null,
  setHideBRF: () => console.log('ctx is not defined')
});

interface HideBRFContextProviderProps {
  children: React.ReactNode;
}

export const useHideBRF: () => HideBRFContextType = () => {
  const ctx = React.useContext(HideBRFContext);

  if (ctx === undefined) {
    throw new Error(`useSideIn should be used within SideInProvider`);
  }
  return ctx;
};

export const HideBRFContextProvider: React.FC<HideBRFContextProviderProps> = ({ children }) => {
  const [hideBRF, setHideBRF] = useState(0);

  return (
    <HideBRFContext.Provider value={{ hideBRF, setHideBRF }}>{children}</HideBRFContext.Provider>
  );
};
